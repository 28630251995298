import type { NavigationItem } from "~/types/navigation";

export const useNavigation = async () => {
    const nuxtApp = useNuxtApp();
    return await useFetch<NavigationItem[]>(`/api/pages?fields[0]=name&fields[1]=slug&populate[0]=jobWorldTag`, {
        getCachedData(key) {
            return nuxtApp.payload.data[key] || nuxtApp.static.data[key];
        },
        transform: cleanData,
    });
};

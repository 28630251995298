<script setup lang="ts">
const { value } = defineProps<{
    value: string;
}>();
const heights: { [key: string]: string } = {
    "Rhino 0.5x": "h-0.5x",
    "Rhino 1x": "h-1x",
    "Rhino 2x": "h-2x",
    "Rhino 3x": "h-3x",
    "Rhino 4x": "h-4x",
    "Rhino 5x": "h-5x",
    "Rhino 6x": "h-6x",
    "Rhino 7x": "h-7x",
    "voller Screen": "h-screen",
};

const style = computed(() => ({ height: `${value.slice(3)}px` }));
</script>

<template>
    <div :class="heights[value]" :style="style"></div>
</template>

export default defineNuxtRouteMiddleware((to, from) => {
    // Retrieve query parameters from the 'from' route
    const fromQuery = { ...from.query };

    // Check if the 'to' route already has the same query parameters as the 'from' route
    const isQuerySame = Object.entries(fromQuery).every(([key, value]) => to.query[key] === value);

    // Remove query parameters from 'from' route that are already present in the 'to' route
    if (fromQuery) {
        for (const key in fromQuery) {
            if (to.query[key]) {
                delete fromQuery[key];
            }
        }

        if (fromQuery.pageId) {
            delete fromQuery.pageId;
        }
    }

    // Redirect only if there are query parameters in the 'from' route and they are not the same as in the 'to' route
    if (Object.keys(fromQuery).length > 0 && !isQuerySame) {
        return navigateTo({
            path: to.path,
            query: { ...to.query, ...fromQuery },
            hash: to.hash,
        });
    }
});

import { convertToSlug } from "~/utils/convertToSlug";

export default defineNuxtRouteMiddleware((to, from) => {
    let toPath = decodeURI(to.path);
    const fromPath = decodeURI(from.path);
    if (toPath.length > fromPath.length) {
        const pathDiff = toPath.replace(fromPath, "");
        const pos = toPath.indexOf(pathDiff);
        if (pos !== -1) toPath = toPath.slice(0, pos + pathDiff.length);
        if (toPath !== decodeURI(to.path)) {
            const slug = encodeURI(convertToSlug(toPath, true, true) as string);
            return navigateTo(slug ?? "", { redirectCode: 301 });
        }
    }

    if (toPath !== convertToSlug(toPath, true, true)) {
        const slug = encodeURI(convertToSlug(toPath, true, true) as string);
        return navigateTo(slug ?? "", { redirectCode: 301 });
    }
    else return;
});

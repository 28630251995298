import { LRUCache } from "lru-cache";

const cache = new LRUCache({
    ttl: 1000 * 60, // 1 minute,
    ttlAutopurge: true,
    noUpdateTTL: true,
});

export const useLocationFromUrl = async (raw = false) => {
    const route = useRoute();

    const navigation = await useNavigationSetting();
    const jobuebersicht = navigation.data?.value?.navigation.jobuebersicht;
    const pathSegments = route.path.split("/");
    const jobuebersichtIndex = pathSegments.findIndex((path) => convertToSlug(decodeURIComponent(path), false) === convertToSlug(jobuebersicht, false));
    let location = jobuebersichtIndex !== -1 && jobuebersichtIndex + 1 < pathSegments.length ? pathSegments[jobuebersichtIndex + 1] : '';

    if (!location) {
        location = route.query.location?.toString() ?? '';
    }
    if (!location) return "";

    if (raw) return location;

    if (parseInt(location)) {
        await doWithLock("location", async () => {
            let locationName = cache.get(location) as string;
            if(!locationName){
                const locationData = (await useLocationNameFromPostalCode(location)) as { data: { value: string } };
                locationName = locationData.data.value;
                cache.set(location, locationName);
            }            
            location = locationName || location;
        });
    }

    let readableLocation = decodeURIComponent(location);
    readableLocation = `${readableLocation.charAt(0).toUpperCase()}${readableLocation.slice(1)}`;

    const loc = readableLocation.split("-(");
    if (loc.length > 1) {
        readableLocation = `${loc[0]} (${loc[1].charAt(0).toUpperCase()}${loc[1].slice(1)}`
    }

    return readableLocation;
};

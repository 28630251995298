//import type { StrapiStyleSetting } from "~/types/StrapiStyleSetting";

import type { GeneralSetting } from "~/types/StrapiGeneralSetting";

export const useNavigationSetting = async () => {
    const nuxtApp = useNuxtApp();

    return await useFetch<GeneralSetting>("/api/general-setting?populate=navigation", {
        getCachedData(key) {
            return nuxtApp.payload.data[key] || nuxtApp.static.data[key];
        },
        transform: cleanData,
    });
};

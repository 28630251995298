<script setup lang="ts">
import type { ButtonStyle } from "~/types/ButtonStyle";
import type { StrapiMedia } from "~/types/StrapiMedia";

const { displayText, url, icon, iconPosRight, buttonStyle } = defineProps<{
    displayText: string;
    url?: string;
    icon?: StrapiMedia;
    iconPosRight?: boolean;
    dense?: boolean;
    buttonStyle?: ButtonStyle;
}>();
const iconSrc = useMedia(icon);
const route = useRoute();
</script>

<template>
    <div
        class="!mx-auto mb-0.25x !flex w-full items-center justify-center md:w-8x"
        :style="{ height: buttonStyle?.height ? buttonStyle?.height + 'px' : 'auto' }"
    >
        <NuxtLink
            :to="convertToSlug(url, true, true, route.path)"
            class="mx-0.5x flex w-full cursor-pointer items-center justify-center rounded-lg border-1 border-ranger-orange bg-ranger-orange px-0.25x font-extrabold text-white no-underline hover:border-ranger-orange-light hover:bg-ranger-orange-light active:border-ranger-orange active:bg-transparent active:text-ranger-orange md:mx-0 md:max-w-screen-lg"
            :class="[dense ? 'py-1 text-xl' : 'py-0.25x text-2xl', buttonStyle ? 'h-full' : 'md:h-1x']"
            :style="{ 'font-size': buttonStyle?.fontsize + 'px' }"
        >
            <img loading="lazy" v-if="iconSrc && !iconPosRight" :src="iconSrc" :alt="icon?.alternativeText" class="mr-0.25x h-0.25x w-0.25x object-contain" />
            <span>{{ displayText }}</span>
            <img loading="lazy" v-if="iconSrc && iconPosRight" :src="iconSrc" :alt="icon?.alternativeText" class="ml-0.25x h-0.25x w-0.25x object-contain" />
        </NuxtLink>
    </div>
</template>

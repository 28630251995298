export const addStrapiRoutes = async () => {
    const router = useRouter();
    const navigation = await useNavigationSetting();
    const { data, error } = await useNavigation();

    if (error.value) {
        console.error(error.value);
    }

    if (!data.value) {
        return;
    }

    const nav = data.value.map(x => x);

    data.value.forEach((item) => {
        const jobWorldPage = nav.find((page) => 
            !!page.jobWorldTag.id
            && !!item.jobWorldTag.id
            && page.jobWorldTag.id === item.jobWorldTag.id
            && (
                page.slug != navigation.data.value?.navigation.jobdetail 
                && page.slug != navigation.data.value?.navigation.jobuebersicht
            )
        ) ?? null;

        const name = item.jobWorldTag?.id ? `${item.name}-${item.jobWorldTag.id}` : item.name;
        // Add the top-level item as a route
        router.addRoute({
            path: convertToSlug(item.slug ?? item.name),
            name: name,
            component: () => import("~/components/PageBuilder.vue").then((r) => r.default || r),
            props: { pageId: item.id, jobWorldTag: item.jobWorldTag.id, jobWorldPage: !jobWorldPage ? null : convertToSlug(jobWorldPage.slug ?? jobWorldPage.name) },
        });
    });

    return { error };
};

/* eslint-disable @typescript-eslint/no-explicit-any */

export const cleanData = (response: any) => {
    if (!response) return null;

    const unwrapData = (data: any): any => {
        if (Array.isArray(data)) {
            // If it's an array, process each element
            return data.map(unwrapData);
        } else if (data && typeof data === "object") {
            if ("data" in data && data.data !== null && typeof data.data === "object") {
                // Check for structures directly containing a 'data' property, implying nested StrapiData
                if (Array.isArray(data.data)) {
                    // If 'data' is an array, it's a collection of StrapiData objects
                    return data.data.map(({ attributes, id }: any) => ({ ...unwrapData(attributes), id }));
                } else if ("id" in data.data && "attributes" in data.data) {
                    // If 'data' is a single StrapiData object
                    const { attributes, id } = data.data;
                    return { ...unwrapData(attributes), id };
                }
            } else if ("id" in data && "attributes" in data) {
                // Directly unwrap 'attributes' for standard StrapiData pattern
                const { attributes, id } = data;
                return { ...unwrapData(attributes), id };
            } else {
                // Process regular objects recursively
                const result: any = {};
                for (const key of Object.keys(data)) {
                    result[key] = unwrapData(data[key]);
                }
                return result;
            }
        } else {
            // Primitives are returned directly
            return data;
        }
    };

    return unwrapData(response.data);
};

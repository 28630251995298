<script setup lang="ts">
const { text, breakAtMark, htmlTag } = defineProps<{
    text: string;
    breakAtMark?: boolean;
    htmlTag?: string | { htmlTag: string; styleClass: string };
}>();
const htmlTagConst = typeof htmlTag === "string" ? htmlTag : htmlTag?.htmlTag ?? "h2";
const styleClass = typeof htmlTag === "string" ? null : htmlTag?.styleClass ?? "";

const headingTags: { [key: string]: string } = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    span: "span",
    // Fake headings are here for backwards compatibility. Should be removed in the future.
    "Subhead XL": "h3",
    Subhead: "h5",
    "fake h1": "span",
    "fake h2": "span",
    "fake h3": "span",
    "fake h4": "span",
};

const elementRef = ref<HTMLElement | null>(null);

const location = await useLocationFromUrl();

const textWithLineBreaks = computed(() => {
    return text
        .replace("(location)", location)
        .split("\\\\")
        .map((part) => part.split('"'));
});

const getSpanClasses = (isHighlighted: boolean, breakAtMark: boolean) => {
    return {
        "text-ranger-orange": isHighlighted,
        block: isHighlighted && breakAtMark,
    };
};
const getHeadingClasses = computed(() => {
    const styleClasses = new Set<string>();
    if (styleClass) {
        styleClasses.add(styleClass);
    }
    // This if-else block is for backwards compatibility.
    if (htmlTagConst.startsWith("Subhead")) {
        styleClasses.add("subhead");
    } else if (htmlTagConst.startsWith("fake")) {
        // Extract the CSS class, e.g. "fake h3" => "h3"
        styleClasses.add(htmlTagConst.substring(5));
    }
    return [...styleClasses].join(" ");
});
</script>

<template>
    <component
        :is="headingTags[htmlTagConst] ?? 'h2'"
        v-if="text"
        ref="elementRef"
        class="!block w-full md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg"
        :class="getHeadingClasses"
    >
        <template v-for="(line, lineIndex) in textWithLineBreaks" :key="lineIndex">
            <span v-for="(part, partIndex) in line" :key="lineIndex + '-' + partIndex" :class="getSpanClasses(partIndex % 2 !== 0, breakAtMark)">
                {{ part }}
            </span>
            <br v-if="lineIndex < textWithLineBreaks.length - 1" />
        </template>
    </component>
</template>

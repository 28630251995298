<script setup lang="ts">
import "~/assets/css/fonts.css";
import "~/assets/css/ckeditor.css";

await addStrapiRoutes();

const runtimeconfig = useRuntimeConfig();

const { cookiebotID } = runtimeconfig.public;

if (cookiebotID) {
    useHead({
        script: [
            {
                async: true,
                id: "Cookiebot",
                src: "https://consent.cookiebot.com/uc.js",
                ["data-cbid"]: cookiebotID,
            },
        ],
    });
}
</script>

<template>
    <div>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

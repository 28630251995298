<script setup lang="ts">
</script>

<template>
    <div class="flex w-full flex-col items-center justify-center">
        <PageVerticalSpacer :value="'Rhino 1x'" />
        <PageHeadingTag :text="$t('error.header')" html-tag="h1" class="mb-0.5x block text-center uppercase" />
        <img class="mb-1x w-5x" src="/assets/robbi_error.png" alt="error" />
        <PageHeadingTag :text="$t('error.text')" html-tag="Subhead XL" class="mb-0.5x block text-center lg:w-8x" />
        <PageLinkButton :display-text="$t('error.button')" url="/" class="lg:w-8x" />
    </div>
</template>
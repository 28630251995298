import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as previewJ9VJgxiihgMeta } from "/app/pages/preview.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93ILszah2VNpMeta?.name ?? "slug___de",
    path: _91_46_46_46slug_93ILszah2VNpMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    alias: _91_46_46_46slug_93ILszah2VNpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ILszah2VNpMeta?.redirect,
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: previewJ9VJgxiihgMeta?.name ?? "preview___de",
    path: previewJ9VJgxiihgMeta?.path ?? "/preview",
    meta: previewJ9VJgxiihgMeta || {},
    alias: previewJ9VJgxiihgMeta?.alias || [],
    redirect: previewJ9VJgxiihgMeta?.redirect,
    component: () => import("/app/pages/preview.vue").then(m => m.default || m)
  }
]